import React from 'react'

function Avatar() {
  return(
    <div style={{borderRadius: '50%', height: '42px', width: '42px', overflow: 'hidden'}}>
      <img className="thumbnail-image" 
          src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9bJnIDPk8tGKFGlwIHfA6SvtyOzEzmf49sA&usqp=CAU'
          alt="user pic"
          style={{height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center'}}
      /> 
    </div> 
  )
}

export default Avatar;