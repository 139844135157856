import React, {Component} from 'react';
import {debounce} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {InputGroup, FormControl} from 'react-bootstrap';

import {
  channelSearch, 
  clearSearches,
  getChannelDetails,
} from '../actions';

class Searchbar extends Component {

  hitSearchFor = debounce((searchChannel)=>{
    this.props.channelSearch(searchChannel);
  }, 500);


  constructor(props){
    super(props);
    this.state = {
      searchFor: '',
    };
  }

  selectChannel = (channel) => {
    const {selectedChannelId} = this.state;
    global.sio.detachListener(selectedChannelId);
    this.props.getChannelDetails(channel['_id']);
    global.sio.attachListener(`${channel['_id']}-message`);
  }

  search = (val) => {
    this.setState({ searchFor: val });
    if(val.length > 2) {
      this.hitSearchFor(val);
    } else if(val.length === 0) {
      this.props.clearSearches();
    }
  }

  render(){
    const {searchChannels, searchFor} = this.props;
    return(
      <div className='searchbar-container'>
        <InputGroup className="searchbar">
          <FormControl
            placeholder="Search Channels ..."
            aria-label="search"
            aria-describedby="search"
            value={searchFor}
            onChange={(e)=>{this.search(e.target.value)}}
          />
        </InputGroup>
        {
          searchChannels.length 
          ? <div className='searchResults'>
              {
                searchChannels.map((search)=>{
                  return(
                    <div onClick={()=>{this.selectChannel(search)}} key={search['_id']} className='d-flex flex-row justify-content-between align-center'>
                      <p>{search.displayName}</p>
                      <p style={{color: '#333', fontSize: '10px'}}>( 125+ members )</p>
                    </div>
                  )
                })
              }
            </div>
          : searchFor
              ? <div className='searchResults'>
                  <p style={{fontSize: '12px'}}>No channels found matching '{searchFor}'</p>
                </div>  
              : null
        }
      </div>
    )
  }
}

const mapStateToProps = ({channelReducer}) => {
  return({
    searchChannels: channelReducer.searchChannels,
  })
}

export default connect(
  mapStateToProps, 
  {
    channelSearch,
    clearSearches,
    getChannelDetails
  }
)(withRouter(Searchbar));