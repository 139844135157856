import axios from 'axios';

import { baseUrl } from '../config';
import { getCookie } from '../handlers/cookies';

import {
  ALL_CHANNELS, 
  GET_CHANNEL,
  ADD_MESSAGES,
  SEARCH_CHANNELS,
  CLEAR_SEARCHES
} from '../action-types';

export const getChannels = () => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization'),
    }
  };
  return (dispatch) => {
    axios.get(
      baseUrl + 'channels',
      config
    ).then(function(response){
      if (response.status === 200){
        dispatch({
          type: ALL_CHANNELS,
          payload: {...response.data.data}
        });
      }
    }).catch(function(error){
      dispatch({
        type: ALL_CHANNELS,
        payload: null
      });
    });
  }
}

export const createChannel = async (data, callback=()=>{}) => {
  let {channelName, channelType, channelAgeGroup} = data;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  let body = {
    name: channelName,
    content_type: channelType,
    age_group: channelAgeGroup
  }
  let res = await axios.post(
    baseUrl+'channel', 
    body, 
    config
  );
  if(res && res.status === 200) {
    callback(true);
    return res;
  } else {
    callback(false);
    return null;
  }
} 

export const getChannelDetails = (channelId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  return (dispatch) => {
    axios.get(
      baseUrl + 'channel/' + channelId,
      config
    ).then(function(response){
      if (response.status === 200){
        dispatch({
          type: GET_CHANNEL,
          payload: {...response.data.data}
        });
        dispatch({
          type: ADD_MESSAGES,
          payload: {...response.data.data, channelId}
        })
      }
    }).catch(function(error){
      dispatch({
        type: GET_CHANNEL,
        payload: null
      });
    });
  }
}

export const channelSearch = (channelName) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  return (dispatch) => {
    axios.get(
      baseUrl + 'channel/search?q=' + channelName,
      config
    ).then(function(response){
      if (response.status === 200){
        dispatch({
          type: SEARCH_CHANNELS,
          payload: {...response.data.data}
        });
      }
    }).catch(function(error){
      dispatch({
        type: SEARCH_CHANNELS,
        payload: null
      });
    });
  }
}

export const joinChannel = (channelId, callback) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  return async(dispatch) => {
    axios.post(
      baseUrl + 'channel/' + channelId + '/join',
      {},
      config
    ).then(function(response){
      callback('success');
    }).catch(function(error){
      callback('failure');
    });
  }  
}

export const unjoinChannel = (channelId, callback= ()=>{}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  return async(dispatch) => {
    axios.delete(
      baseUrl + 'channel/' + channelId + '/join',
      config
    ).then(function(response){
      callback('success');
      dispatch({
        type: 'CHANNEL_UNJOINED',
        payload: {channelId: channelId}
      })
    }).catch(function(error){
      callback('failure');
    });
  }
}

export const clearSearches = () => {
  return async(dispatch) => {
    dispatch({
      type: CLEAR_SEARCHES,
      payload: {}
    });
  }
}
