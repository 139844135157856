import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ChatBubble from '../elements/chatBubble';

function MessagesWindow(props) {
  const { messages, selectedChannelId, loggedInUserid } = props;
  if(selectedChannelId && messages.hasOwnProperty(selectedChannelId)) {
    return (
      <div  className="chat-window">
      {
        messages[selectedChannelId].map(message =>
          (
            <ChatBubble
              key={message['_id']}
              message={message}
              sentByMe={message.senderId === loggedInUserid ? true : false}
            />
          )
        )
      }
      </div>
    );
    
  }

  return(
    <p>No Messages In Chat...</p>
  )

}

const mapStateToProps = ({messageReducer}) => {
  return({
    messages: messageReducer.allMessages
  })
}

export default connect(
  mapStateToProps, {}
)(withRouter(MessagesWindow));