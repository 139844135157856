import axios from 'axios';

import { baseUrl } from '../config';
import { getCookie } from '../handlers/cookies';

import {
  ADD_MESSAGE
} from '../action-types';


export const sendMessage = (message, channelId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'userid': getCookie('userid'),
      'authorization': getCookie('authorization')
    }
  };
  let body = {message};
  return (dispatch) => {
    axios.post(
      baseUrl+'channel/'+channelId+'/message', 
      body, 
      config
    ).then(function(response){
      if (response.status === 200){
        dispatch({
          type: ADD_MESSAGE,
          payload: {...response.data}
        });
      }
    }).catch(function(error){
      dispatch({
        type: ADD_MESSAGE,
        payload: null
      });
    });
  }
}