import React from 'react';

function WelcomeScreen () {
  return(
    <div className='welcome-screen'>
      <div className='logo'>
        <p className='welcome-text'>hāˈsən</p>
      </div>
    </div>
  )
}

export default WelcomeScreen;