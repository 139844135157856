import React from 'react';

function PageNotFound () {
  return(
    <div className='d-flex justify-content-center align-items-center' style={{height: '100vh', width: '100vw'}}>
      <p className='welcome-text'>PAGE NOT FOUND.</p>
    </div>
  )
}

export default PageNotFound;