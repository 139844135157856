import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {withRouter} from 'react-router';
import Login from './login';
import Signup from './signup';
import { getCookie } from '../handlers/cookies';

import {loginUser, signupUser} from '../actions';

class Auth extends Component { 
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let userid = getCookie('userid');
    if(userid) {
      this.props.history.push('/home');
    }
  }

  componentDidUpdate = (prevProps) => {

    if(prevProps.userDetails !== this.props.userDetails) {
      if(this.props.userDetails['_id']) {
        this.props.history.push('/home');
      }
    }
  }

  makeAuthRequest = (data, type) => {
    if(type === 'login') {
      this.props.loginUser(data);
    } else {
      this.props.signupUser(data);
    }
  }  

  render() {
    return(
      <div className="container">
        <Row className='mt-5'>
          <Col>
            <Login makeAuthRequest={(data, type)=>this.makeAuthRequest(data, type)} />
          </Col>
          <Col lg={2}></Col>
          <Col>
            <Signup makeAuthRequest={(data, type)=>this.makeAuthRequest(data, type)} />
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({userReducer}) => {
  return({
    userDetails: userReducer.userDetails
  })
}

export default connect(
  mapStateToProps, {
    loginUser,
    signupUser
  })(withRouter(Auth));