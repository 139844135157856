import axios from 'axios';

import { baseUrl } from '../config';
import { getCookie } from '../handlers/cookies';

export const syncSocketId = () => {
  let userid = getCookie('userid');
  let authorization = getCookie('authorization')
  let sid = global.sid;
  if(userid && authorization && sid) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'userid': userid,
        'authorization': authorization
      }
    };
    try {
      axios.post(
        baseUrl + 'socket',
        {sid},
        config
      ).then((res)=>{
        console.log("Token Synced !!!")
      }).catch((err)=>{
        console.log("Error while Syncing Token !!!")
      })
    }
    catch(err) {
      console.log('err', err);
    }
  }
}
