import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getCookie} from '../handlers/cookies';
import {Button, InputGroup, Form, Modal, Navbar} from 'react-bootstrap';
import MessagesWindow from './messagesWindow';
import emoticons from '../constants/emoji';

import {
  sendMessage, 
  joinChannel,
  unjoinChannel,
  getChannelDetails
} from '../actions';

class ChatSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      channelDetails: {},
      selectedChannelId: '',
      message: '',
      detailsModalOpen: false,
      joined: false,
      showEmojiModal: false
    };
    this.loggedInUserid = getCookie('userid');
  }

  componentDidUpdate = (prevProps) => {
    console.log("Did Update Running")
    const {selectedChannelId: propsChannelId, channels} = this.props;
    if (propsChannelId) {
      const { channelDetails: { joined }, selectedChannelId } = this.state;
      const propsChannelDetails = channels[propsChannelId];
      if(selectedChannelId !== propsChannelId) {
        this.setState({
          selectedChannelId: propsChannelId,
          channelDetails: {
            ...propsChannelDetails
          },
          joined: propsChannelDetails['joined']
        });
      } else if (joined !== propsChannelDetails['joined']) {
        this.setState({
          selectedChannelId,
          channelDetails: {
            ...propsChannelDetails,
          },
          joined: propsChannelDetails['joined']
        });
      }
    }
  }

  toggleEmojiModal = () => {
    this.setState({
      showEmojiModal: !this.state.showEmojiModal
    })
  }

  setEmoji = (emoji) => {
    let {message} = this.state;
    this.setState({
      showEmojiModal: false,
      message: message + ` ${emoji}`
    })
  }

  openDetailsModal = () => {
    this.setState({detailsModalOpen: true});
  }

  closeDetailsModal = () => {
    this.setState({detailsModalOpen: false});
  }

  subscribeChannel = (channelId) => {
    this.props.joinChannel(channelId,()=>{
      this.props.getChannelDetails(channelId);
    });
  }

  unSubscribeChannel = (channelId) => {
    this.props.unjoinChannel(channelId, (res) => {
      if(res === 'success') {
        this.closeDetailsModal()
      }
    });
  }

  handleChatSubmit = (e) => {
    const {message, selectedChannelId} = this.state;
    e.preventDefault();
    if(message && selectedChannelId) {
      this.props.sendMessage(message, selectedChannelId);
      this.setState({message: ''})
    } else {
      alert("Unable to send blank message !")
    }
  }

  render(){
    const {selectedChannelId, detailsModalOpen, channelDetails, joined, showEmojiModal} = this.state;
    if(!selectedChannelId) {
      return( 
        <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <p style={{fontSize: '24px', fontWeight: '700', color: '#cacaca'}}>Select Channel to Chat</p>
        </div>  
      )  
    }

    return(
      <>
        <Navbar className='navbar custom-nav' sticky="top" >
          <Navbar.Brand>
            <p className='mb-0' style={{color: '#000'}}>{channelDetails.displayName}</p>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end"> 
          {
            joined 
            ? <img 
                onClick={() => {this.openDetailsModal()}}
                src='https://image000.flaticon.com/png/512/1/1176.png'
                style={{height: '18px', width: '18px'}}
                alt="icon"
              />
            : null
          }
            
          </Navbar.Collapse>
        </Navbar>

        <MessagesWindow 
          selectedChannelId={selectedChannelId}
          loggedInUserid={this.loggedInUserid}
        />

        {
          this.loggedInUserid
          ? joined
            ? <div className="send-message-bar">
                <InputGroup>
                  <InputGroup.Append>
                    <Button className='btn' onClick={this.toggleEmojiModal} variant='dark'>Emoji</Button>
                  </InputGroup.Append>
                  <Form.Control 
                    as="textarea" 
                    placeholder="Send Message"
                    aria-label="Send_message"
                    aria-describedby="message"
                    onChange={(e) => {this.setState({message: e.target.value})}}
                    value={this.state.message}
                    rows={2} />
                  <InputGroup.Append>
                    <Button className='btn' onClick={this.handleChatSubmit} variant='secondary'>Send</Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            : <div 
                style={{ height: '60px', backgroundColor: '#333', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 16px'}}>
                <p className='mb-0' style={{color: '#fff', fontSize: '14px'}}>Join Channel Now to take part in conversation.</p>
                <Button className="btn btn-sm mr-2" onClick={() => this.subscribeChannel(selectedChannelId)} variant="warning">+ Join</Button>
              </div>
          : <div 
              style={{ height: '60px', backgroundColor: '#333', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 16px'}}>
              <p className='mb-0' style={{color: '#fff', fontSize: '14px'}}>Join Channel Now to take part in conversation.</p>
              <Button className="btn btn-sm mr-2" onClick={() => {this.props.history.push('/auth')}} variant="primary">Login</Button>
            </div>
        
        } 

        {/* .............................Modal................................... */}

        {
          detailsModalOpen
          ? <Modal
              show={detailsModalOpen}
              onHide={this.closeDetailsModal}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{channelDetails['displayName']}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <p><b>Creator:</b><span className='ml-3'>{channelDetails['creator']['name'] || channelDetails['creator']['displayName'] }</span></p>
                  <Button className="btn btn-sm mr-2" onClick={() => this.unSubscribeChannel(selectedChannelId)} variant="danger">Unjoin Channel</Button>
              </Modal.Body>
            </Modal>
          : null
        }

        <Modal
          show={showEmojiModal}
          onHide={this.toggleEmojiModal}
          keyboard={true}
        >
          <Modal.Body style={{maxHeight: '90%'}}>
            <div className='d-flex justify-content-around' style={{flexWrap: 'wrap', overflow: 'scroll', height: '100%', width: '100%'}}>
              {
                emoticons.map(emoji => {
                  return(
                    <span
                      onClick={()=>{this.setEmoji(emoji)}}
                      className="emoji"
                      role="img"
                      style={{padding: '8px'}}
                      // aria-label={props.label ? props.label : ""}
                      // aria-hidden={props.label ? "false" : "true"}
                    >
                      {emoji}
                    </span>
                  )
                })
              }
            </div>
          </Modal.Body>
        </Modal>

      </>
    ) 
  }
}

const mapStateToProps = ({channelReducer}) => {
  return({
    channels: channelReducer.channels,
    selectedChannelId: channelReducer.selectedChannelId
  })
}

export default connect(
  mapStateToProps, 
  {
    sendMessage,
    joinChannel,
    unjoinChannel,
    getChannelDetails
  }
)(withRouter(ChatSection));