import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ChannelCard from '../elements/channelCard';

import {
  getChannels, 
  getChannelDetails
} from '../actions';

class ChannelSection extends Component {

  constructor(props){
    super(props);
    this.state = {
      channelCategories: [],
      selectedChannelId: '',
    };
  }

  componentDidMount = () => {
    this.props.getChannels();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {channelCategories, selectedChannelId} = this.props;
    console.log("Insdljfnlnd 1289`2y38t612fyveiqkn")
    if(prevProps.channelCategories.length !== channelCategories.length ||
      prevState.channelCategories.length !== channelCategories.length
    ) {
      this.setState({channelCategories: channelCategories});
    }

    if(prevProps.selectedChannelId !== selectedChannelId) {
      this.setState({selectedChannelId});
    }
  }

  componentWillUnmount = () => {
    const {selectedChannelId} = this.state;
    if(selectedChannelId) {
      global.sio.detachListener(selectedChannelId);
    }
  }

  selectChannel = (channel) => {
    const {selectedChannelId} = this.state;
    this.props.getChannelDetails(channel['_id']);
    if(selectedChannelId && !channel['joined']) {
      try{
        global.sio.detachListener(selectedChannelId);
      } catch (err) {
        // Failed to remove listener !!!
      }
    }
    if(!channel['joined']) {
      global.sio.attachListener(`${channel['_id']}-message`);
    }
  }

  render(){
    const {channelCategories} = this.state;
    const {channels, channelMappings} = this.props;
    if(!channelCategories.length) {
      return <p>No Channels Found !</p>
    }
    return(
      <div className='channelsContainer'>
        {
          channelCategories.map((category) => {
            let channelIds = channelMappings[category['key']] || [];
            if(channelIds.length) {
              return(
                <div className='mb-4' key={category['key']}>
                  <p className='p-2' style={{backgroundColor: '#333', color: '#fff', textAlign: 'center'}}><b>{category['name']}</b></p>
                    <div className='pl-2 pr-2'>
                      {
                        channelIds.map(val => {
                          return(
                            <ChannelCard
                              selectChannel={(channel)=>{this.selectChannel(channel)}} 
                              key={val} 
                              data={channels[val]} />
                          )
                        })
                      }
                    </div>  
                </div>
              )
            }
            return (<></>);
          })
        }
      </div>
    )
  }
}

const mapStateToProps = ({channelReducer, messageReducer}) => {
  console.log('channelReducer', channelReducer);
  return({
    channelCategories: channelReducer.channelCategories,
    channelMappings: channelReducer.channelMappings,
    channels: channelReducer.channels,
    selectedChannelId: channelReducer.selectedChannelId,
  })
}

export default connect(
  mapStateToProps, 
  {
    getChannels, 
    getChannelDetails
  }
)(withRouter(ChannelSection));