import { io } from "socket.io-client";
import {store} from '../index';

import {syncSocketId} from '../actions';
import {
	SOCKET_MESSAGE_UPDATE,
	SOCKET_UPDATE_MY_CHANNELS,
	SOCKET_MESSAGE_UPDATE_CHANNEL,
} from '../action-types';

const SOCKET_URL = "https://socket.hastenweb.com";
// const SOCKET_URL = "http://127.0.0.1:8000";

class SocketHandler {
	socket;

	constructor(){
		this.connect();
  }

  connect = () => {
		this.socket = io(SOCKET_URL);
		this.socket.on('updates', data => {
			console.log('socketUpdates', data)
			const dataType = data['type'];
			if (dataType === 'sync') {
				global.sid = data['sid'];
				if(global.sid) {
					syncSocketId();
				}
			}
			if(dataType === 'sync_channels') {
				let res = data['mychannels'];
				store.dispatch({
					type: SOCKET_UPDATE_MY_CHANNELS,
					payload: {data: res}
				})
			}
		});
  }

  attachListener = (eventName = "") => {
		console.log("Attaching Events For", eventName)
  	if (eventName) {
  		this.socket.on(eventName, data => {
				console.log('MessageArrived', data)
				if(data && data['_id']) {
					store.dispatch({
						type: SOCKET_MESSAGE_UPDATE,
						payload: {data}
					})
					store.dispatch({
						type: SOCKET_MESSAGE_UPDATE_CHANNEL,
						payload: {data}
					})
				}
	    });
  	} else {
      console.warn("Event Name Empty");
  	}
  }

  detachListener = (eventName) => {
  	if (eventName) {
	  	this.socket.removeAllListeners(eventName, data => {
	    	// setResponse(data);
	    });
    } else {
      console.warn("Event Name Empty");
  	}
  }
}

let socketConn = new SocketHandler();

export default socketConn; 