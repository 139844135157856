import {
  LOGIN_USER, 
  SIGNUP_USER
} from '../action-types';

const INITIAL_STATE = {
  userDetails: {}
}

const channel = (state=INITIAL_STATE, action) => {
  let { payload } = action;
  let data = payload && payload.data ? payload.data : null;

  switch(action.type) {
    case LOGIN_USER:
      if(data) {
        return {
          ...state,
          userDetails: payload.data
        }
      }
    return state;

    case SIGNUP_USER:
      if(data) {
        return {
          ...state,
          userDetails: payload.data
        }
      }
    return state;

    default: 
      return state;
  }
}

export default channel;