import axios from 'axios';
import { baseUrl } from '../config';
import {syncSocketId} from './socket';
import {
  LOGIN_USER,
  SIGNUP_USER
} from '../action-types';
import { setCookie } from '../handlers/cookies';

const options = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const loginUser = (data) => {
  return (dispatch) => {
    axios.post(
      baseUrl + 'login',
      data,
      options
    ).then(function(response){
      if (response.status === 200){
        const {userid, authorization} = response.headers;
        const {data: {data}} = response;
        let name = data.username || 'Hasten User';
        setCookie('userid', userid);
        setCookie('authorization', authorization);
        setCookie('userName', name);
        syncSocketId();
        dispatch({
          type: LOGIN_USER,
          payload: {...response.data}
        });
      }
    }).catch(function(error){
      dispatch({
        type: LOGIN_USER,
        payload: null
      });
    });
  }
}

export const signupUser = (data) => {
  return (dispatch) => {
    axios.post(
      baseUrl + 'signup',
      data,
      options
    ).then(function(response){
      const {userid, authorization} = response.headers;
      const {data: {data}} = response;
      let name = data.username || 'Hasten User';
      setCookie('userid', userid);
      setCookie('authorization', authorization);
      setCookie('userName', name);
      syncSocketId();
      if (response.status === 200){
        dispatch({
          type: SIGNUP_USER,
          payload: {...response.data}
        });
      }
    }).catch(function(error){
      dispatch({
        type: SIGNUP_USER,
        payload: null
      });
    });
  }
}

