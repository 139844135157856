export const SET_CHANNEL = 'SET_CHANNEL';
export const ALL_CHANNELS = 'ALL_CHANNELS';
export const GET_CHANNEL = 'GET_CHANNEL';
export const SOCKET_UPDATE_MY_CHANNELS = 'SOCKET_UPDATE_MY_CHANNELS';

export const SEARCH_CHANNELS = 'SEARCH_CHANNELS';
export const CLEAR_SEARCHES = 'CLEAR_SEARCHES';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const SOCKET_MESSAGE_UPDATE = 'SOCKET_MESSAGE_UPDATE';
export const SOCKET_MESSAGE_UPDATE_CHANNEL = 'SOCKET_MESSAGE_UPDATE_CHANNEL';

export const LOGIN_USER = 'LOGIN_USER';
export const SIGNUP_USER = 'SIGNUP_USER';