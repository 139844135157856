import React, {useState} from 'react';
import { Button, Form} from 'react-bootstrap';

import {createChannel} from '../actions';

function AddChannel(props) {
  let [channelName, setChannelName] = useState('');
  let [channelType, setChannelType] = useState('#');
  let [channelAgeGroup, setChannelAgeGroup] = useState('all');

  function handleSubmit(e) {
    if(channelName && channelType) {
      createChannel({channelName, channelType, channelAgeGroup}, (success) => {
        if(success) {
          props.closeModal()
        }
      });
    } else {
      alert('Please Fill Mandatory Details !!!');
    }
    e.preventDefault();
    return false;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Channel Name *</Form.Label>
        <Form.Control 
          placeholder="hasten" 
          value={channelName} 
          onChange={(e)=>{setChannelName(e.target.value)}}
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Channel Type *</Form.Label>
        <select onChange={(e)=>{ setChannelType(e.target.value) }} value={channelType} className="form-control">
          <option value='#'>#</option>
          <option value='@'>@</option>
          <option value='/'>/</option>
        </select>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Age Group</Form.Label>
        <select onChange={(e)=>{setChannelAgeGroup(e.target.value)}} value={channelAgeGroup} className="form-control">
          <option value='all'>all</option>
          <option value='18+'>18+</option>
          <option value='18-'>18-</option>
        </select>
      </Form.Group>
      <Button variant="primary" type="submit">
        Add Channel
      </Button>
    </Form>
  )
}

export default AddChannel