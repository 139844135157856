import {
  ADD_MESSAGE,
  ADD_MESSAGES,
  SOCKET_MESSAGE_UPDATE
} from '../action-types';

import {getCookie} from '../handlers/cookies';

const INITIAL_STATE = {
  allMessages: {}  // {channelId: [...messages], ...};
}

const message = (state=INITIAL_STATE, action) => {
  let { payload } = action;

  function messageEntry(data = {}) {
    const {channel, content, temp_id} = data;
    let _id = '';
    let channelId = channel;
    let message = content;
    let tempId = temp_id;
    let status = 'Sending';
    let senderId = getCookie('userid');
    let senderName = getCookie('userName');
    let clone = {...state.allMessages};
    let dump = {};
    if(clone.hasOwnProperty(channelId)) {  //Channel Exists, Append Data
      dump = {
        ...clone,
        [channelId]: [
          ...clone[channelId],
          { _id, message, senderId, tempId, senderName, channelId, status }
        ]
      }
    } else {  //New Channel, Create Data
      dump = {
        ...clone,
        [channelId]: [
          { _id, message, senderId, tempId, senderName, channelId, status }
        ]
      }
    }
    return {...dump};
  }

  function dbMessagesEntry(data = [], channelId) {
    let mssgs = [];
    let clone = {...state.allMessages};
    if(!clone.hasOwnProperty(channelId)) {
      data.forEach((item) => {
        const {_id, content, sent_by} = item;
        mssgs.push({
          _id: _id,
          tempId: '',
          message: content,
          channelId: channelId,
          senderId: sent_by['_id'],
          senderName: sent_by['username'], 
          status: 'Seen',
        })
      });
    } else {
      mssgs = [...clone[channelId]]
    }
    return {
      ...clone,
      [channelId]: mssgs
    }
  }


  function getSocketMessage(data = {}) {
    const {content, sent_by, channel, temp_id, _id} = data;
    let dump = {};
    let clone = {...state.allMessages};
    let tempId = temp_id;
    let senderName = sent_by['username'];
    let message = content;
    let senderId = sent_by['_id'];
    let channelId = channel['_id'];
    // let loggedInUserId = getCookie('userid');
    if(clone.hasOwnProperty(channelId)) {
      let channelMessages = [...clone[channelId]];
      let index = channelMessages.findIndex(item => {return (item.tempId === tempId)});

      if(index > -1) {  //message Exists
        dump = clone;
        dump[channelId][index]['_id'] = _id;
        dump[channelId][index]['status'] = 'Sent';
      } else {
        dump = {   // mesage not exisst
          ...clone,
          [channelId]: [
            ...clone[channelId],
            {
              _id,
              message, 
              senderId,
              tempId,
              senderName: senderName,
              channelId,
              status: ''
            }
          ]
        }
      }
    } else {
      dump = {
        ...clone,
        [channelId]: [
          {
            _id,
            message, 
            senderId,
            tempId,
            senderName: senderName,
            channelId,
            status: 'Received'
          }
        ]
      }
    }
    return {...dump};
  }

  switch(action.type) {

    case SOCKET_MESSAGE_UPDATE:
      if(payload && payload.data) {
        return {
          ...state,
          allMessages: getSocketMessage(payload.data)
        }
      }
    return state;

    case ADD_MESSAGE:
      if(payload && payload.data) {
        return {
          ...state,
          allMessages: messageEntry(payload && payload.data)
        }
      }
      return state;

      case ADD_MESSAGES:
        if(payload && payload.messages) {
          return {
            ...state,
            allMessages: dbMessagesEntry(payload.messages, payload.channelId)
          }
        }
        return state;  

    default: 
      return state;
  }
}

export default message;