const emoticons = [
  "😃",
  "🐻",
  "🍔",
  "⚽",
  "🌇",
  "💡",
  "🔣",
  "🎌",
  "😃",
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "🤣",
  "😂",
  "🙂",
  "🙃",
  "😉",
  "😊",
  "😇",
  "🥰",
  "😍",
  "🤩",
  "😘",
  "😗",
  "☺️",
  "😚",
  "😙",
  "🥲",
  "😋",
  "😛",
  "😜",
  "🤪",
  "😝",
  "🤑",
  "🤗",
  "🤭",
  "🤫",
  "🤔",
  "🤐",
  "🤨",
  "😐",
  "😑",
  "😶",
  "😏",
  "😒",
  "🙄",
  "😬",
  "🤥",
  "😌",
  "😔",
  "😪",
  "🤤",
  "😴",
  "😷",
  "🤒",
  "🤕",
  "🤢",
  "🤮",
  "🤧",
  "🥵",
  "🥶",
  "🥴",
  "😵",
  "🤯",
  "🤠",
  "🥳",
  "🥸",
  "😎",
  "🤓",
  "🧐",
  "😕",
  "😟",
  "🙁",
  "☹️",
  "😮",
  "😯",
  "😲",
  "😳",
  "🥺",
  "😦",
  "😧",
  "😨",
  "😰",
  "😥",
  "😢",
  "😭",
  "😱",
  "😖",
  "😣",
  "😞",
  "😓",
  "😩",
  "😫",
  "🥱",
  "😤",
  "😡",
  "😠",
  "🤬",
  "😈",
  "👿",
  "💀",
  "☠️",
  "💩",
  "🤡",
  "👹",
  "👺",
  "👻",
  "👽",
  "👾",
  "🤖",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "💋",
  "👋",
  "🤚",
  "🖐️",
  "✋",
  "🖖",
  "👌",
  "🤌",
  "🤏",
  "✌️",
  "🤞",
  "🤟",
  "🤘",
  "🤙",
  "👈",
  "👉",
  "👆",
  "🖕",
  "👇",
  "☝️",
  "👍",
  "👎",
  "✊",
  "👊",
  "🤛",
  "🤜",
  "👏",
  "🙌",
  "👐",
  "🤲",
  "🤝",
  "🙏",
  "✍️",
  "💅",
  "🤳",
  "💪",
  "🦾",
  "🦿",
  "🦵",
  "🦶",
  "👂",
  "🦻",
  "👃",
  "🧠",
  "🫀",
  "🫁",
  "🦷",
  "🦴",
  "👀",
  "👁️",
  "👅",
  "👄",
  "👶",
  "🧒",
  "👦",
  "👧",
  "🧑",
  "👱",
  "👨",
  "🧔",
  "👨‍🦰",
  "👨‍🦱",
  "👨‍🦳",
  "👨‍🦲",
  "👩",
  "👩‍🦰",
  "🧑‍🦰",
  "👩‍🦱",
  "🧑‍🦱",
  "👩‍🦳",
  "🧑‍🦳",
  "👩‍🦲",
  "🧑‍🦲",
  "👱‍♀️",
  "👱‍♂️",
  "🧓",
  "👴",
  "👵",
  "🙍",
  "🙍‍♂️",
  "🙍‍♀️",
  "🙎",
  "🙎‍♂️",
  "🙎‍♀️",
  "🙅",
  "🙅‍♂️",
  "🙅‍♀️",
  "🙆",
  "🙆‍♂️",
  "🙆‍♀️",
  "💁",
  "💁‍♂️",
  "💁‍♀️",
  "🙋",
  "🙋‍♂️",
  "🙋‍♀️",
  "🧏",
  "🧏‍♂️",
  "🧏‍♀️",
  "🙇",
  "🙇‍♂️",
  "🙇‍♀️",
  "🤦",
  "🤦‍♂️",
  "🤦‍♀️",
  "🤷",
  "🤷‍♂️",
  "🤷‍♀️",
  "🧑‍⚕️",
  "👨‍⚕️",
  "👩‍⚕️",
  "🧑‍🎓",
  "👨‍🎓",
  "👩‍🎓",
  "🧑‍🏫",
  "👨‍🏫",
  "👩‍🏫",
  "🧑‍⚖️",
  "👨‍⚖️",
  "👩‍⚖️",
  "🧑‍🌾",
  "👨‍🌾",
  "👩‍🌾",
  "🧑‍🍳",
  "👨‍🍳",
  "👩‍🍳",
  "🧑‍🔧",
  "👨‍🔧",
  "👩‍🔧",
  "🧑‍🏭",
  "👨‍🏭",
  "👩‍🏭",
  "🧑‍💼",
  "👨‍💼",
  "👩‍💼",
  "🧑‍🔬",
  "👨‍🔬",
  "👩‍🔬",
  "🧑‍💻",
  "👨‍💻",
  "👩‍💻",
  "🧑‍🎤",
  "👨‍🎤",
  "👩‍🎤",
  "🧑‍🎨",
  "👨‍🎨",
  "👩‍🎨",
  "🧑‍✈️",
  "👨‍✈️",
  "👩‍✈️",
  "🧑‍🚀",
  "👨‍🚀",
  "👩‍🚀",
  "🧑‍🚒",
  "👨‍🚒",
  "👩‍🚒",
  "👮",
  "👮‍♂️",
  "👮‍♀️",
  "🕵️",
  "🕵️‍♂️",
  "🕵️‍♀️",
  "💂",
  "💂‍♂️",
  "💂‍♀️",
  "🥷",
  "👷",
  "👷‍♂️",
  "👷‍♀️",
  "🤴",
  "👸",
  "👳",
  "👳‍♂️",
  "👳‍♀️",
  "👲",
  "🧕",
  "🤵",
  "🤵‍♂️",
  "🤵‍♀️",
  "👰",
  "👰‍♂️",
  "👰‍♀️",
  "🤰",
  "🤱",
  "👩‍🍼",
  "👨‍🍼",
  "🧑‍🍼",
  "👼",
  "🎅",
  "🤶",
  "🧑‍🎄",
  "🦸",
  "🦸‍♂️",
  "🦸‍♀️",
  "🦹",
  "🦹‍♂️",
  "🦹‍♀️",
  "🧙",
  "🧙‍♂️",
  "🧙‍♀️",
  "🧚",
  "🧚‍♂️",
  "🧚‍♀️",
  "🧛",
  "🧛‍♂️",
  "🧛‍♀️",
  "🧜",
  "🧜‍♂️",
  "🧜‍♀️",
  "🧝",
  "🧝‍♂️",
  "🧝‍♀️",
  "🧞",
  "🧞‍♂️",
  "🧞‍♀️",
  "🧟",
  "🧟‍♂️",
  "🧟‍♀️",
  "💆",
  "💆‍♂️",
  "💆‍♀️",
  "💇",
  "💇‍♂️",
  "💇‍♀️",
  "🚶",
  "🚶‍♂️",
  "🚶‍♀️",
  "🧍",
  "🧍‍♂️",
  "🧍‍♀️",
  "🧎",
  "🧎‍♂️",
  "🧎‍♀️",
  "🧑‍🦯",
  "👨‍🦯",
  "👩‍🦯",
  "🧑‍🦼",
  "👨‍🦼",
  "👩‍🦼",
  "🧑‍🦽",
  "👨‍🦽",
  "👩‍🦽",
  "🏃",
  "🏃‍♂️",
  "🏃‍♀️",
  "💃",
  "🕺",
  "🕴️",
  "👯",
  "👯‍♂️",
  "👯‍♀️",
  "🧖",
  "🧖‍♂️",
  "🧖‍♀️",
  "🧘",
  "🧑‍🤝‍🧑",
  "👭",
  "👫",
  "👬",
  "💏",
  "👩‍❤️‍💋‍👨",
  "👨‍❤️‍💋‍👨",
  "👩‍❤️‍💋‍👩",
  "💑",
  "👩‍❤️‍👨",
  "👨‍❤️‍👨",
  "👩‍❤️‍👩",
  "👪",
  "👨‍👩‍👦",
  "👨‍👩‍👧",
  "👨‍👩‍👧‍👦",
  "👨‍👩‍👦‍👦",
  "👨‍👩‍👧‍👧",
  "👨‍👨‍👦",
  "👨‍👨‍👧",
  "👨‍👨‍👧‍👦",
  "👨‍👨‍👦‍👦",
  "👨‍👨‍👧‍👧",
  "👩‍👩‍👦",
  "👩‍👩‍👧",
  "👩‍👩‍👧‍👦",
  "👩‍👩‍👦‍👦",
  "👩‍👩‍👧‍👧",
  "👨‍👦",
  "👨‍👦‍👦",
  "👨‍👧",
  "👨‍👧‍👦",
  "👨‍👧‍👧",
  "👩‍👦",
  "👩‍👦‍👦",
  "👩‍👧",
  "👩‍👧‍👦",
  "👩‍👧‍👧",
  "🗣️",
  "👤",
  "👥",
  "🫂",
  "👣",
  "🧳",
  "🌂",
  "☂️",
  "🎃",
  "🧵",
  "🧶",
  "👓",
  "🕶️",
  "🥽",
  "🥼",
  "🦺",
  "👔",
  "👕",
  "👖",
  "🧣",
  "🧤",
  "🧥",
  "🧦",
  "👗",
  "👘",
  "🥻",
  "🩱",
  "🩲",
  "🩳",
  "👙",
  "👚",
  "👛",
  "👜",
  "👝",
  "🎒",
  "🩴",
  "👞",
  "👟",
  "🥾",
  "🥿",
  "👠",
  "👡",
  "🩰",
  "👢",
  "👑",
  "👒",
  "🎩",
  "🎓",
  "🧢",
  "🪖",
  "⛑️",
  "💄",
  "💍",
  "💼",
  "🩸",
  "😮‍💨",
  "😵‍💫",
  "😶‍🌫️",
  "😃",
  "🐻",
  "🍔",
  "⚽",
  "🌇",
  "💡",
  "🔣",
  "🎌",
  "❤️",
  "✨",
  "🥺",
  "🔥",
  "😂",
  "😊",
  "✔️",
  "🥰",
  "🥲",
  "🧗",
  "🤖",
  "🎮",
  "🍏",
  "🆕",
  "💅",
  "🥺",
  "😭",
  "🇦🇺",
  "🇫🇷",
  "🎂",
  "🛍️",
  "✊🏿",
  "🇨🇦",
  "🇧🇷",
  "🐉",
  "🎅",
  "🇲🇽",
  "🦠",
  "🪔",
  "🇨🇳",
  "🌱",
  "🐰",
  "🎥",
  "🍂",
  "👨",
  "💪",
  "🌿",
  "🎓",
  "🔥",
  "🎃",
  "🕎",
  "💕",
  "🕉️",
  "🇺🇸",
  "♀️",
  "🤱",
  "🎊",
  "🔞",
  "🏊",
  "🏳️‍🌈",
  "🎭",
  "👑",
  "☪️",
  "🌱",
  "☘️",
  "☀️",
  "🏈",
  "🦃",
  "💘",
  "🎖️",
  "👰",
  "⛄",
  "🎿",
  "🏡",
  "⚽",
  "🌎"
];

export default emoticons;