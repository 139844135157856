import React from 'react';

function ChatBubble (props) {
  let {message, sentByMe} = props;
  if(sentByMe) {
    return(
      <div className='message-container-right'>
        <div className='right-message'>
          <div className='chat-bubble-right'>
            <p className='message-content-right'>{message.message}</p>
            {/* <p className='message-time-right'>5:30 pm</p> */}
          </div>
          <p className='message-status-right'>{message.status}</p>
        </div>  
      </div>
    )
  } else {
    return(
      <div className='message-container-left'>
        <div className='left-message'>
          <div className='chat-bubble-left'>
            <p className='message-content-left ml-2'>{message.message}</p>
            {/* <p className='message-time-left'>5:30 pm</p> */}
          </div>
          <p className='message-sender-left' style={{fontSize: '10px', fontStyle: 'italic'}}>{message.senderName}</p>
        </div>  
      </div>
    )
  }
}

export default ChatBubble;