import { combineReducers } from "redux";

import channel from './channel';
import message from './message';
import user from './user';

const Reducer = combineReducers({
  channelReducer: channel,
  messageReducer: message,
  userReducer: user
})

export default Reducer;
