import {
  ALL_CHANNELS,
  GET_CHANNEL,
  SEARCH_CHANNELS,
  CLEAR_SEARCHES,
  SOCKET_UPDATE_MY_CHANNELS,
  SOCKET_MESSAGE_UPDATE_CHANNEL
  
} from '../action-types';

const INITIAL_STATE = {
  channelCategories: [],
  channelMappings: [],
  channels: {},
  searchChannels: [],
  selectedChannelId: '',
}

const channel = (state = INITIAL_STATE, action) => {
  let { payload } = action;

  function createChannelObj(data = {}) {
    const {_id, content_type, last_message, name, joined, description, created_by} = {...data};
    if (data && data['_id']) {
      return {
        _id: _id,
        contentType: content_type,
        unreadMessages: 0,
        lastMessage: last_message || '',
        name: name,
        joined: joined,
        description: description || '',
        displayName: content_type + name,
        creator: {
          displayName: created_by.username,
          name: created_by.name,
          _id: created_by._id
        }
      }
    }
    return {};
  }

  switch (action.type) {

    case ALL_CHANNELS:
      if (payload) {
        let clone = { ...payload };
        let categories = clone.categories;
        let categoryKeys = [];
        let channels = {};
        let mappings = {};
        categories.forEach(category => {
          return categoryKeys.push(category.key);
        });
        if (categoryKeys.length) {
          categoryKeys.forEach(categoryName => {
            clone[categoryName].forEach(channel => {
              if(categoryName === 'mychannels') {    // attaching listeners on myChannels            
                global.sio.attachListener(`${channel['_id']}-message`);
              }
              if (!channels.hasOwnProperty(channel['_id'])) {  //Appending to channels only if it doesn't exist
                let temp = channel;
                channels = {
                  ...channels,
                  [temp['_id']]: createChannelObj(temp)
                }
              }
              mappings = {
                ...mappings,
                [categoryName]: [
                  ...(mappings[categoryName] || []),
                  channel['_id']
                ]
              }
            })
          })
        }
        return {
          ...state,
          channelCategories: categories,
          channels: channels,
          channelMappings: mappings
        }
      }
      return state 

    case GET_CHANNEL:
      if (payload) {
        let id = payload['channel_details']['_id'];
        let channels = state.channels;
        let newData = createChannelObj(payload['channel_details']);
        channels = {
          ...channels,
          [id]: newData
        }
        return {
          ...state,
          channels: channels,
          selectedChannelId: id
        }
      }
      return state;

    case SOCKET_MESSAGE_UPDATE_CHANNEL:
        if(payload && payload.data) {
          let messageObj = {...payload.data};
          // let messageId = messageObj['_id'];
          let channelId = messageObj['channel']['_id'];
          let channels = state.channels;
          if(messageObj['_id'] && channelId !== state.selectedChannelId) {
            channels = {
              ...channels,
              [channelId]: {
                ...channels[channelId],
                unreadMessages: Number(channels[channelId]['unreadMessages']) + 1,
                lastMessage: messageObj.content
              }
            }
          } 
          return {
            ...state,
            channels: channels,
          }
        }
        return state;

    case SOCKET_UPDATE_MY_CHANNELS:  //will only handle myChannels updation...
      if(payload && payload.data) {
        let clone = [...payload.data];
        let channelsClone = {...state.channels};
        let mappings = {...state.channelMappings,mychannels:[]};
          clone.forEach(channel => {   
            let channelId = channel['_id'];
          global.sio.attachListener(`${channelId}-message`);
          channelsClone = {
            ...channelsClone,
            [channelId]: createChannelObj(channel),
          }
          mappings = {
            ...mappings,
            'mychannels': [
              ...(mappings['mychannels'] || []),
              channel['_id']
            ]
          }
        })
        return {
          ...state,
          channels: channelsClone,
          channelMappings: mappings
        }
      }
      return state;    

    case 'CHANNEL_UNJOINED' :
      if(payload && payload.channelId) {
        let channelId = payload.channelId;
        let clone = {
          ...state.channels,
          [channelId]: {
            ...state.channels[channelId],
            joined: false
          }
        };
        return {
          ...state,
          channels: clone
        }
      }
      return state;

    case SEARCH_CHANNELS:
      if (payload && payload.channels) {
        let dump = [];
        payload.channels.forEach(temp => {
          dump.push(createChannelObj(temp));
        })
        return {
          ...state,
          searchChannels: dump
        }
      }
      return state;
      

    case CLEAR_SEARCHES:
      return {
        ...state,
        searchChannels: []
      }

    default:
      return state;
  }
}

export default channel;