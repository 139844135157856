import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import WelcomePage from './components/welcome';
import HomePage from './components/home';
import AuthPage from './components/auth';
import PageNotFound from './components/pageNotFound'


const Routers = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={WelcomePage} />
        <Route exact path='/home' component={HomePage} />
        <Route exact path='/auth' component={AuthPage} />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  )
}


export default Routers;