import React from 'react';
import { Row, Col} from 'react-bootstrap';
import ChannelSection from './channelSection';
import ChatSection from './chatSection';
import NavigationBar from './navbar';

function Home() {
  return(
    <Row className='gx-0'>
      <Col className='home_left-section' lg={5} md={4} sm={6}>
        <NavigationBar />
        <ChannelSection />
      </Col>
      <Col className='home_right-section' lg={7} md={6} sm={6}>
        <ChatSection />
      </Col>
    </Row>
  )
}

export default Home;
