import React from 'react';
import {Badge, Button} from 'react-bootstrap';

function ChannelCard(props) {

  let {data} = props;

  function breakString(str) {
    if(str && str.length > 80) {
      let temp = str.substring(0, 80)
      return temp + ' ...'
    }
    return str;
  }

  return (
    <Button 
      style={{width: '100%'}}
      variant='light' 
      onClick={()=>{props.selectChannel(data)}}
      className='btn btn-sm mb-1 mt-2'>
      <div style={{width: '100%'}} className='d-flex flex-row justify-content-between align-center'>
        <div style={{ marginRight: '12px',width: '100%', textAlign: 'start' }}>
          <p className='mb-1'>
            <b>{data['displayName']}</b>
            <span style={{fontSize: '10px'}}>( +63 members )</span>
          </p>
          <p style={{color: '#c9c9c9', fontSize: '12px', fontStyle: 'italic', paddingLeft: '8px'}}>
            {breakString(data.lastMessage)}
          </p>
        </div>
        <div>
          {
            data.unreadMessages
            ? <Badge style={{alignSelf: 'flex-end', width: 'min-content'}} pill variant="dark">{data.unreadMessages}</Badge>   
            : <p></p>
          }
        </div>
      </div>
    </Button>
  )
}

export default ChannelCard;