import React from 'react';
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducer from './reducers';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

import Routers from './routers';
import sio from './handlers/socket';

import './styles/index.css';

global.sio = sio;
global.sid = '';

export const store = createStore( Reducer, {}, applyMiddleware(ReduxThunk));

function App() {
  return (
    <Provider store={store}>
      <Routers />
    </Provider>
  );
}

export default App;
