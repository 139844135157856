import React, { Component } from 'react';
import Searchbar from './searchbar';
import ChannelListing from './channelListing';


class ChannelSection extends Component {
  constructor(props) {
    super(props);
    this.state={};
  }

  render(){
    return(
      <>
        <Searchbar />
        <ChannelListing />
      </>
    )
  }
}

export default ChannelSection;