import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';

function Signup(props) {
  const [mobile, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    if(mobile && email && password) {
      props.makeAuthRequest({email, password, mobile}, 'signup');
    } else {
      alert("All details are mandatory !");
    }
  }

  return(
    <>
      <p style={{fontSize: '36px', fontWeight: '700'}}>Signup</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address *</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter email" 
            value={email} 
            onChange={(e)=>{setEmail(e.target.value)}}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Phone Number *</Form.Label>
          <Form.Control 
            type="phone" 
            placeholder="Enter phone" 
            value={mobile} 
            onChange={(e)=>{setPhone(e.target.value)}}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password *</Form.Label>
          <Form.Control 
            value={password} 
            onChange={(e)=>{setPassword(e.target.value)}}
            type="password" 
            placeholder="Password" 
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  )
}

export default Signup;