export const getCookie = (key) => {
  let cookie = document.cookie || '';
  if(cookie && key) {
    let fetchedCookie = '';
    let fetchedCookieArr = cookie.split('; ').find(row => row.startsWith(`${key}=`));
    if(fetchedCookieArr) {
      fetchedCookie = fetchedCookieArr.split('=')[1];
    }
    return fetchedCookie;
  }
  return '';
}

export const setCookie = (key, value) => {
  if(key && value) {
    document.cookie = `${key}=${value}; max-age=31536000`;
  }
  return null;
}

export const  deleteCookies = () => {
  var allCookies = document.cookie.split(';');
  for (var i = 0; i < allCookies.length; i++)
      document.cookie = allCookies[i] + "=;expires="
      + new Date(0).toUTCString();
}