import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {Navbar, Button, Modal} from 'react-bootstrap';
import AddChannel from './addChannel';
import Avatar from '../elements/avatar';
import {deleteCookies, getCookie} from '../handlers/cookies';

function Home(props) {
  let history = useHistory();
  let [profileVisible, setProfileVisible] = useState(false);
  let [channelModal, showChannelModal] = useState(false);

  let isLoggedIn = getCookie('userid');
  let userName = getCookie('userName');
  return(
    <>
      <Navbar className='navbar custom-nav' sticky="top" >
        <Navbar.Brand>
          <p 
          onClick={()=>{window.location='/'}} 
          style={{
            fontWeight: '600',
            fontSize: '20px',
            margin: '0px',
            padding: '0px 7px',
            border: '2.5px solid black'
        }}>hāˈsən</p>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">

          {
            isLoggedIn
            ? <Button
                onClick={()=>{showChannelModal(true)}} 
                variant='dark' 
                className='btn btn-sm m-2'>
                + Add Channel
              </Button>
            : null
          }

          {
            isLoggedIn ? 
              <div
                onClick={()=>{setProfileVisible(true)}}
                style={{borderRadius: '50%', overflow: 'hidden'}}>
                <Avatar />
              </div>
            : <Button
                onClick={()=>{
                  history.push("/auth");
                }} 
                variant='primary' 
                className='btn btn-sm m-2'>
                Login
              </Button>
          }
        </Navbar.Collapse>
      </Navbar>

      {/*---------------------------Modals------------------------*/}

      <Modal
        size="sm"
        show={profileVisible}
        onHide={() => setProfileVisible(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img className="thumbnail-image" 
                src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9bJnIDPk8tGKFGlwIHfA6SvtyOzEzmf49sA&usqp=CAU'} 
                alt="user pic"
                style={{height: '120px', width: '120px', objectFit: 'cover', objectPosition: 'center', marginBottom: '16px'}}
            /> 
            <p style={{fontStyle: 'italic', marginBottom: '16px'}}>{userName}</p>
            <Button
              onClick={()=>{
                deleteCookies()
                window.location = '/auth'
              }} 
              variant='danger' 
              className='btn btn-sm m-2'>
              Logout
            </Button>
          </div>

        </Modal.Body>
      </Modal>

      <Modal
          show={channelModal}
          onHide={()=>{showChannelModal(false)}}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>   
            <AddChannel closeModal={()=>{showChannelModal(false)}} />
          </Modal.Body>
        </Modal>

    </>
  )
}

export default Home;
